import Vue from 'vue'
import VueRouter from 'vue-router'

var routes = []

// autoRouter文件夹自动路由渲染
const ctx = require.context('../views/autoRouter', true, /\.vue$/)
routes.push({
  path: '',
  name: 'autoRouterParent',
  component: () => import('../views/autoRouterparent.vue'),
  children: []
})
for (const key of ctx.keys()) {
  let vueModule = ctx(key), splitArr = key.slice(1).replace('.vue', '').split('/')
  // splitArr.splice(0, 1)
  routes[0].children.push({
    path: splitArr.join('/'),
    name: vueModule.default.name,
    meta: {
      title: vueModule.default.title
    },
    component: vueModule.default
  })
}

// 添加手动注册路由
routes.unshift({
  path: '/',
  name: 'defaulte',
  meta: {
    title: "登录"
  },
  redirect: '/login'
})
routes.push.apply(routes, [{
  path: '/login',
  name: 'login',
  meta: {
    title: "登录"
  },
  component: () => import('../views/login.vue')
}, {
  path: '/readme',
  name: 'readme',
  meta: {
    title: "项目说明"
  },
  component: () => import('../views/readme.vue')
}, {
  path: '*',
  name: 'nofount',
  meta: {
    title: "资源文件未找到"
  },
  component: () => import('../views/notFound.vue')
}])
Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}-${process.env.VUE_APP_PROJECT_NAME}客服调度平台`
  next()
})
export default router
