<template>
  <div>
    <el-card v-if="!mobile">
      <el-form inline ref="form">
        <el-form-item label="订单号">
          <el-input v-model="searchData.orderId" placeholder="请输入订单号查找"></el-input>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input v-model="searchData.userName" placeholder="请输入用户姓名查找"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="searchData.mobile" placeholder="请输入手机号查找"></el-input>
        </el-form-item>
        <el-form-item label="车牌号">
          <el-input v-model="searchData.plate" placeholder="请输入车牌号查找"></el-input>
        </el-form-item>
        <el-form-item label="停车场">
          <el-select v-model="searchData.parkId" clearable filterable remote reserve-keyword placeholder="请输入关键词查询停车场" :remote-method="getParkAreaList">
            <el-option v-for="item in parkingAreaList" :key="item.id" :label="item.parkingName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="车位号">
          <el-input v-model="searchData.seatNum" placeholder="请输入车位号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="$refs.table.reloadData()">搜索</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <cui-table :url="$api.selectAbnormalOrderList" :data="searchData" ref="table" width="200">
      <el-table-column fixed prop="id" label="订单ID" width="150"></el-table-column>
      <el-table-column label="订单类型" width="100">
        <template slot-scope="scope">
          <el-tag size="medium" v-if="Number(scope.row.arrearsAmount)>0" type="danger">欠费订单</el-tag>
          <el-tag size="medium" v-else type="success">正常订单</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="订单用户姓名" width="170">
        <template slot-scope="scope">{{scope.row.userName?scope.row.userName+`【${scope.row.userMobile}】`:"临时用户"}}</template>
      </el-table-column>
      <el-table-column prop="plate" label="车牌号" width="150">
        <template slot-scope="scope">
          <el-tag size="medium" v-if="!scope.row.plate" type="danger">无牌车</el-tag>
          <p v-else>【{{scope.row.plateColorRemark}}】{{scope.row.plate}}</p>
        </template>
      </el-table-column>
      <el-table-column prop="parkingName" label="停车场" width="180"></el-table-column>
      <el-table-column prop="seatNum" label="车位编号" min-width="180"></el-table-column>
      <el-table-column prop="enterTime" label="进场时间" width="150"></el-table-column>
      <el-table-column label="进场图片" width="90">
        <template slot-scope="scope">
          <el-image v-if="scope.row.inPic" style="width: 30px; height: 30px" :src="scope.row.inPic" :preview-src-list="[scope.row.inPic]"></el-image>
          <el-tag v-else type="info">暂无图片</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="leaveTime" label="出场时间" width="150"></el-table-column>
      <el-table-column prop="leaveAt" label="出场图片" width="90">
        <template slot-scope="scope">
          <el-image v-if="scope.row.outPic" style="width: 30px; height: 30px" :src="scope.row.outPic" :preview-src-list="[scope.row.outPic]"></el-image>
          <el-tag v-else type="info">暂无图片</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="payAmount" label="支付金额" width="100">
        <template slot-scope="scope">{{scope.row.payAmount}}元</template>
      </el-table-column>
      <!-- <el-table-column prop="payAmount" label="欠费金额" width="100">
        <template slot-scope="scope">{{Number(scope.row.arrearsAmount)>0?scope.row.arrearsAmount:"无欠费"}}</template>
      </el-table-column> -->
      <el-table-column prop="payTime" label="支付时间" width="150"></el-table-column>
      <el-table-column prop="status" label="标记状态" width="100">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status==0" type="success">正常订单</el-tag>
          <el-tag v-else-if="scope.row.status==1">0元放行</el-tag>
          <el-tag v-else-if="scope.row.status==2">退款</el-tag>
          <el-tag v-else-if="scope.row.status==3">补偿优惠券</el-tag>
          <el-tag v-else-if="scope.row.status==4">修改进出场</el-tag>
          <el-tag v-else-if="scope.row.status==100" type="warning">异常待处理</el-tag>
          <el-tag v-else type="danger">状态异常</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="160">
        <template slot-scope="scope">
          <!-- <el-button type="text" disabled @click="selectUrgeDetail(scope.row.userId)">修改订单信息</el-button> -->
          <el-button type="text" :disabled="scope.row.status!=100" @click="showDealErrorOrder(scope.row.id,scope.row.userId,scope.row.parkId)">安抚处理</el-button>
          <el-button type="text" @click="selectAbnormalOrderProcess(scope.row.id)">处理记录</el-button>
        </template>
      </el-table-column>
    </cui-table>

    <el-dialog :title="dialogData.title" :visible.sync="dialogData.show" :width="dialogData.width" :modal="false" :close-on-click-modal="false">
      <el-tabs v-model="dialogData.dealType" type="card">
        <el-tab-pane label="赠送优惠券" name="1" :disabled="!dealInfo.userId">
          <el-form label-width="100px">
            <el-form-item :required="true" label="选择优惠券">
              <el-select v-model="dealInfo.couponId" placeholder="请选择优惠卷" style="width:80%">
                <el-option v-for="item in couponsList" :key="item.id" :label="`${item.title}【剩余${item.couponCount}张】`" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :required="true" label="赠送数量">
              <el-input-number v-model="dealInfo.couponCount" :min="1" :max="Number(dealInfo.couponTotal)" label="补偿数量">
              </el-input-number>
              <!-- <el-tag size="medium" style="margin-left:-20%" type="success">{{"剩余"+remainCoupon+"张"}} -->
              <!-- </el-tag> -->
            </el-form-item>
            <el-form-item :required="true" label="补偿原因：">
              <el-input type="textarea" :rows="3" placeholder="请输入补偿原因" v-model="dealInfo.couponReason"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="compensationCoupon()">提 交</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <!-- <el-tab-pane label="订单退款" name="2">
          <el-form label-width="100px">
            <el-form-item :required="true" label="退款金额">
              <el-input-number v-model="dealInfo.refundMoney" :precision="2" :step="1" :min="0.00"></el-input-number>
            </el-form-item>
            <el-form-item :required="true" label="退款金额">
              <el-input type="textarea" :rows="3" placeholder="请输入退款原因" v-model="dealInfo.refundReason">
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="refundAbnormalOrderInfo()">提 交</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane> -->
        <el-tab-pane label="开闸放行" name="3">
          <el-form label-width="100px">
            <el-form-item>
              <el-button type="primary" @click="vehicleRelease()">0元开闸放行</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <el-dialog title="处理详情" :visible.sync="ifDetailShow" width="400px">
      <p style="line-height:2em">处理方式：{{titles}}</p>
      <p style="line-height:2em">用户姓名：{{detailData.username}}</p>
      <p style="line-height:2em">车牌号码：{{detailData.plate}}</p>
      <p style="line-height:2em">{{cause}}：{{detailData.reason}}</p>
      <p v-if="!!detailData.refundMoney" style="line-height:2em">退款金额：{{detailData.refundMoney+"元"}}</p>
      <p v-if="!!detailData.discountRule" style="line-height:2em">优惠规则：{{detailData.discountRule.length>3?detailData.discountRule+'卷':'优惠'+detailData.discountRule+'小时卷'}}</p>
      <p v-if="!!detailData.count" style="line-height:2em">补偿数量：{{detailData.count+"张"}}</p>
      <p style="line-height:2em">客服编号：{{detailData.receptionistId}}</p>
    </el-dialog>

    <el-dialog title="处理详情" :visible.sync="ifDetailShow2" width="30%">
      <p style="line-height:2em">处理方式：0元放行</p>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: "orderError",
  title: "异常订单",
  props: ["mobile"],
  data() {
    return {
      searchData: {
        orderId: "",
        userName: "",
        mobile: "",
        plate: "",
        parkId: "",
        seatNum: "",
        orderType: 40,
        currentPage: 1,
        pageSize: 10,
        count: 0,
      },
      parkingAreaList: [],
      dialogData: {
        title: "",
        show: false,
        width: "500px",
        type: "deal",
        dealType: "1",
      },
      dealInfo: {
        errorOrderId: "",
        userId: "",
        couponId: "",
        couponTotal: 1,
        couponCount: 1,
        couponReason: "",
        refundMoney: 0,
        refundReason: "",
      },
      couponsList: [],

      // 处理详情
      ifDetailShow: false,
      ifDetailShow2: false,
      detailData: {},
      titles: "",
      cause: "",
    };
  },
  watch: {
    mobile: function (value) {
      this.searchData.mobile = value;
      this.$refs.table.reloadData();
    },
  },
  created() {
    this.mobile ? (this.searchData.mobile = this.mobile) : "";
  },
  methods: {
    showDealErrorOrder(errorOrderId, userId, parkId) {
      this.getCouponsList();
      this.dialogData.title = "异常订单安抚处理";
      this.dialogData.show = true;
      this.dialogData.width = "500px";
      this.dialogData.type = "deal";
      this.dealInfo.errorOrderId = errorOrderId;
      this.dealInfo.userId = userId;
      userId
        ? (this.dialogData.dealType = "1")
        : (this.dialogData.dealType = "2");
      this.dealInfo.parkId = parkId;
    },
    selectAbnormalOrderProcess(id) {
      this.$http.get(this.$api.selectAbnormalOrderProcess, { id }, (res) => {
        if (res.list.length == 0) {
          this.ifDetailShow2 = true;
        } else {
          if (res.list[0].type == 10) {
            this.titles = "0元放行";
            this.cause = "放行原因";
            this.handleTime = "放行时间";
          } else if (res.list[0].type == 20) {
            this.titles = "补偿优惠卷";
            this.cause = "补偿原因";
            this.handleTime = "补偿时间";
          } else if (res.list[0].type == 30) {
            this.titles = "申请退款";
            this.cause = "退款原因";
            this.handleTime = "退款时间";
          }
          this.detailData = res.list[0];
          this.ifDetailShow = true;
        }
      });
    },
    compensationCoupon() {
      this.$http.post(
        this.$api.compensationCoupon,
        {
          count: this.dealInfo.couponCount,
          errorOrderId: this.dealInfo.errorOrderId,
          id: this.dealInfo.couponId,
          reason: this.dealInfo.couponReason,
          userId: this.dealInfo.userId,
        },
        (res) => {
          Object.assign(this.$data.dialogData, this.$options.data().dialogData);
          Object.assign(this.$data.dealInfo, this.$options.data().dealInfo);
          this.$message.success("优惠券赠送成功！");
          this.$refs.table.reloadData();
        }
      );
    },
    refundAbnormalOrderInfo() {
      this.$http.post(
        this.$api.refundAbnormalOrderInfo,
        {
          orderId: this.dealInfo.errorOrderId,
          reason: this.dealInfo.refundReason,
          refundMoney: this.dealInfo.refundMoney,
        },
        (res) => {
          Object.assign(this.$data.dialogData, this.$options.data().dialogData);
          Object.assign(this.$data.dealInfo, this.$options.data().dealInfo);
          this.$message.success("退款申请提交成功！");
          this.$refs.table.reloadData();
        }
      );
    },
    vehicleRelease() {
      this.$http.post(
        this.$api.vehicleRelease,
        { orderId: this.dealInfo.errorOrderId, parkId: this.dealInfo.parkId },
        (res) => {
          Object.assign(this.$data.dialogData, this.$options.data().dialogData);
          Object.assign(this.$data.dealInfo, this.$options.data().dealInfo);
          this.$message.success("开闸指令已下发！");
          this.$refs.table.reloadData();
        }
      );
    },
    getCouponsList() {
      this.$http.post(this.$api.getCouponInfo, {}, (res) => {
        this.couponsList = res.list;
      });
    },
    getParkAreaList(query) {
      if (query !== "") {
        this.$http.post(
          this.$api.getParkAreaList,
          {
            currentPage: 1,
            pageSize: 10,
            parkingName: query,
          },
          (res) => {
            this.parkingAreaList = res.list;
          }
        );
      } else {
        this.parkingAreaList = [];
      }
    },
  },
};
</script>
